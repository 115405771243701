@import '../globals.scss';

#contact{
  min-height: 75vh;
  height: auto;

  .header {
    height: 60vh;
    background-image: url('../../img/about_header_image.min.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $white;
    position: relative;
    z-index: 0;

    @media only screen and (max-width: $mobileBreakpoint) {
      background-position: calc(50% - 70px) 50%;
    }

    .title-container {
      position: absolute;
      bottom: 50px;
      left: 5%;
      backdrop-filter: blur(3px);
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 20px;

      @media only screen and (max-width: $mobileBreakpoint) {
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
      }

      h1 {
        font-size: 6rem;
        color: $white;
        margin: 0;
        text-transform: uppercase;
        font-weight: bold;

        @media only screen and (max-width: $mobileBreakpoint) {
          font-size: 2.8rem;
          text-align: center;
        }
      }

      h2 {
        font-weight: bold;
        font-size: 3rem;
        color: $white;
        margin: 0;
        margin-top: -10px;
        text-transform: uppercase;
        letter-spacing: 15px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px;

        @media only screen and (max-width: $mobileBreakpoint) {
          font-size: 1.8rem;
          text-align: center;
          -webkit-text-stroke: 1px;
          margin-top: 0px;
          letter-spacing: 1px;
        }
      }
    }
  }

  .content{
    padding-left: 5%;
    padding-right: 5%;

    h1 {
      font-size: 2.5rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 2vh;

      @media only screen and (max-width: $mobileBreakpoint) {
        font-size: 1.5rem;
        text-align: center;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;

      @media only screen and (max-width: $mobileBreakpoint) {
        font-size: 1rem;
        text-align: justify;
      }
    }
  }

  .socials-container{
    margin-bottom: 15vh;

    @media only screen and (max-width: $mobileBreakpoint) {
      text-align: center;
    }
  }

  .contact-form-container{
    margin-top: 10vh;
    margin-bottom: 10vh;

    form{
      width: 50%;

      @media only screen and (max-width: $mobileBreakpoint) {
        width: 100%;
      }

      .form-group{
        margin-bottom: 2vh;
        display: flex;
        flex-direction: column;

        label{
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 0.5vh;
        }

        input, textarea{
          padding: 1vh;
          border: 1px solid $black;
          border-radius: 5px;
          font-size: 1.2rem;
        }
      }

      .submit-btn{
        background-color: $green;
        color: $white;
        padding: 1vh;
        border: none;
        border-radius: 5px;
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover{
          background-color: $white;
          color: $black;
          outline: 1px solid $black;
        }
      }
    }
  }

  .error-message{
    color: $red;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2vh;
  }

  .success-message{
    color: $green;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2vh;
  }

  .required{
    color: $red;
  }
}