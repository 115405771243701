@import '../globals.scss';

.card-activity {
  background-color: $white;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid $green;
  width: 70%;
  min-height: 25vh;
  height: auto;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: $mobileBreakpoint) {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .card-title {
    font-size: 1.8rem;
    color: $black;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (max-width: $mobileBreakpoint) {
      font-size: 1.5rem;
    }
  }
}

.card-travel {
  background-color: $white;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid $green;
  width: 70%;
  min-height: 25vh;
  height: auto;
  margin-right: 5vw;

  @media only screen and (max-width: $mobileBreakpoint) {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .card-title {
    font-size: 1.8rem;
    color: $black;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (max-width: $mobileBreakpoint) {
      font-size: 1.5rem;
    }
  }


  .start,
  .end {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 1.5rem;
      color: $black;
      margin: 0;

      @media only screen and (max-width: $mobileBreakpoint) {
        font-size: 1.2rem;
      }
    }

    .date {
      font-size: 1.5rem;
      color: $black;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0;

      @media only screen and (max-width: $mobileBreakpoint) {
        font-size: 1rem;
      }
    }
  }

  .start {
    align-items: flex-start;
  }

  .end {
    align-items: flex-end;
  }


  .timeline {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .stats {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: center;
    margin-top: 30px;

    .stat-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 10px;

      .total-distance,
      .step-end-place {
        text-align: right;
      }

      .step-distance,
      .total-distance,
      .step-start-place,
      .step-end-place {
        .title {
          font-size: 1.2rem;
          color: $black;
          margin: 0;

          @media only screen and (max-width: $mobileBreakpoint) {
            font-size: 1rem;
          }
        }

        .value {
          font-size: 1rem;
          color: $black;
          text-transform: uppercase;
          font-weight: bold;
          margin: 0;

          @media only screen and (max-width: $mobileBreakpoint) {
            font-size: .8rem;
          }
        }
      }
    }
  }
}

.card-info {
  background-color: $white;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid $green;
  width: 70%;
  min-height: 25vh;
  height: auto;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $mobileBreakpoint) {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .title {
    font-size: 1.8rem;
    color: $black;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;

    @media only screen and (max-width: $mobileBreakpoint) {
      font-size: 1.2rem;
    }
  }

  .value {
    font-size: 1.5rem;
    color: $black;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;

    @media only screen and (max-width: $mobileBreakpoint) {
      font-size: 1.2rem;
    }
  }
}