@import '../globals.scss';

#home {
  .header {
    height: 60vh;
    background-image: url('../../img/header_image.min.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $white;
    position: relative;
    z-index: 0;

    @media only screen and (max-width: $mobileBreakpoint) {
      background-position: calc(50% + 50px) 50%;
    }

    .title-container {
      position: absolute;
      bottom: 50px;
      left: 5%;
      backdrop-filter: blur(3px);
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 20px;

      @media only screen and (max-width: $mobileBreakpoint) {
        left: 50%;
        transform: translateX(-50%);
      }

      h1 {
        font-size: 6rem;
        color: $white;
        margin: 0;
        text-transform: uppercase;
        font-weight: bold;

        @media only screen and (max-width: $mobileBreakpoint) {
          font-size: 4rem;
          text-align: center;
        }
      }

      h2 {
        font-weight: bold;
        font-size: 3rem;
        color: $white;
        margin: 0;
        margin-top: -20px;
        text-transform: uppercase;
        letter-spacing: 2px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px;

        @media only screen and (max-width: $mobileBreakpoint) {
          font-size: 1.8rem;
          text-align: center;
          -webkit-text-stroke: 1px;
          margin-top: 0px
        }
      }
    }
  }

  .direct {
    padding-left: 5%;
    padding-right: 5%;

    h1 {
      color: $black;
      text-transform: uppercase;
      font-weight: bold;

      @media only screen and (max-width: $mobileBreakpoint) {
        font-size: 1.5rem;
        text-align: center;
      }
    }

    .cards-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 50px;

      .container-part{
        width: 50%;

        @media only screen and (max-width: $mobileBreakpoint) {
          width: 100%;
        }
      }

      @media only screen and (max-width: $mobileBreakpoint) {
        flex-direction: column;
      }
    }
  }

  .content {
    margin-top: 10vh;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 20vh;
    width: 100%;

    h1 {
      color: $black;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 50px;

      @media only screen and (max-width: $mobileBreakpoint) {
        font-size: 1.5rem;
        text-align: center;
      }
    }

    .scroll-handle {
      // background-color: red;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      // background-color: grey;
      padding: 10px;

      @media only screen and (max-width: $mobileBreakpoint) {
        display: none;
      }

      .left-arrow-container,
      .right-arrow-container {
        position: relative;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: rgba($black, .02);
        }
      }
    }

    .actus-container {
      display: flex;
      flex-direction: row;
      align-items: space-between;
      width: 100%;

      .posts-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
      }
    }
  }
}