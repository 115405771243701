@import '../globals.scss';

#notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100vw;
  text-align: center;

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 2rem;
  }

  a {
    color: $green;
    text-decoration: none;
    font-size: 1.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
}