@import '../globals.scss';

.step{
  border: 2px solid $green;
  border-radius: 20px;
  padding: 10px;
  width: 360px;
  height: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;

  @media only screen and (max-width: $mobileBreakpoint) {
    width: 100%;
    height: 10vh;
    margin-bottom: 2vh;
  }

  p{
    margin: 0;
  }

  .step-title{
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
}