@import '../globals.scss';

.navbar {
  height: 5vh;
  width: 70%;
  background-color: $white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 3vh;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 2;
  // transition: .3s;

  @media only screen and (max-width: $mobileBreakpoint) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navbar-left {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $mobileBreakpoint) {
      width: 20%;
    }

    .navbar-logo {
      img {
        max-height: 4vh;
      }
    }
  }

  .navbar-right {
    width: 80%;
    // background-color: red;

    @media only screen and (max-width: $mobileBreakpoint) {
      width: 80%;
      display: flex;
      justify-content: flex-end;
      // background-color: red;
    }

    .navbar-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      @media only screen and (max-width: $mobileBreakpoint) {
        justify-content: flex-end;
        display: none;
      }

      .navbar-menu-item {
        font-size: 1.2rem;
        font-weight: bold;
        color: $black;
        text-decoration: none;
        // transition: all 0.3s ease-in-out;
        list-style: none;
        text-transform: uppercase;
        padding: 5px 20px 5px 20px;
        border-radius: 30px;
        margin-left: 20px;
        // background-color: blue;

        @media only screen and (max-width: 1700px) {
          font-size: 1rem;
        }

        @media only screen and (max-width: 1230px) {
          text-align: center;
        }

        &:hover {
          background-color: $green;
        }

        .navbar-menu-link {
          text-decoration: none;
          color: $black;
          transition: .3s;
          // background-color: green;

          &:hover {
            color: $white;
          }
        }
      }

      /* Dropdown Button */
      .navbar-menu-dropdown-button {
        background-color: $white;
        border: none;
        color: $black;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 5px 20px 5px 20px;
        border-radius: 20px;

        &:hover{
          background-color: $green;
          color: $white;
          border-radius: 20px 20px 0px 0px;
        }
      }

      /* The container <div> - needed to position the dropdown content */
      .navbar-menu-dropdown {
        position: relative;
        display: inline-block;

        &:hover{
          .navbar-menu-dropdown-content{
            display: block;
          }
        }
      }

      /* Dropdown Content (Hidden by Default) */
      .navbar-menu-dropdown-content {
        display: none;
        position: absolute;
        background-color: $white;
        min-width: 210px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        border-radius: 0px 20px 20px 20px;

        a{
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 1.2rem;
          font-weight: bold;
          display: block;

          &:first-child{
            border-radius: 0px 20px 0px 0px;
          }

          &:last-child{
            border-radius: 0px 0px 20px 20px;
          }

          &:hover{
            background-color: $green;
            color: $white;
          }
        }
      }

      /* Change color of dropdown links on hover */
      .dropdown-content a:hover {background-color: #ddd;}

      /* Show the dropdown menu on hover */
      .dropdown:hover .dropdown-content {display: block;}

      /* Change the background color of the dropdown button when the dropdown content is shown */
      .dropdown:hover .dropbtn {background-color: #3e8e41;}
    }

    .navbar-burger {
      display: none;

      @media only screen and (max-width: $mobileBreakpoint) {
        display: block;
      }
    }
  }
}

.burger-modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .3s;

  .burger-links {
    padding-left: 0;

    .burger-links-item {
      font-size: 1.5rem;
      font-weight: bold;
      color: $black;
      text-decoration: none;
      transition: .3s;
      list-style: none;
      text-transform: uppercase;
      padding: 10px 20px 10px 20px;
      border-radius: 30px;
      text-align: center;
      margin-bottom: 20px;

      &:hover {
        background-color: $green;
      }

      .burger-links-link {
        text-decoration: none;
        color: $black;
        transition: .3s;

        &:hover {
          color: $white;
        }
      }
    }

    .burger-close-btn {
      font-size: 1.5rem;
      font-weight: bold;
      color: $red;
      cursor: pointer;
      transition: .3s;
      text-align: center;
    }
  }
}

.active {
  background-color: $green;

  .navbar-menu-link {
    color: $white !important;
  }

  .burger-links-link {
    color: $white !important;
  }
}

.navbar-active {
  outline: 2px solid $green;
}