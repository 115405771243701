@import '../globals.scss';

#footer {
  height: 25vh;
  background-color: $green;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left,
    .right {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
    }

    .left {
      justify-content: center;
      align-items: flex-start;

      @media only screen and (max-width: $mobileBreakpoint) {
        width: 30%;
      }

      img {
        max-height: 10vh;
      }
    }

    .right {
      align-items: flex-end;
      justify-content: center;

      @media only screen and (max-width: $mobileBreakpoint) {
        width: 70%;
      }

      p {
        color: $white;
        font-size: 1.2rem;
        margin: 0;

        @media only screen and (max-width: $mobileBreakpoint) {
          font-size: .8rem;
          text-align: right;
        }
      }

      .separator {
        width: 50%;
        height: 1px;
        background-color: $white;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .socials {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 20%;

        a {
          color: $white;
        }
      }
    }
  }

  .copyright {
    p {
      color: $white;
      font-size: 1rem;
      margin: 0;
      text-align: center;

      @media only screen and (max-width: $mobileBreakpoint) {
        font-size: .8rem;
      }
    }
  }
}